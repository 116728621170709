<template>
  <div class="header-box">
    <div class="header-one">
      <i class="el-icon-s-fold header-icon" @click="fold"></i>
      <div class="header-user" @click="logout">
        {{ userInfo.name }}
        <i class="el-icon-d-arrow-right"></i>
        <div class="user-shou">
          <span class="close">退出</span>
        </div>
      </div>
    </div>
    <div class="menu-box">
      <el-scrollbar ref="scroll" @wheel.native.prevent="handleScroll">
        <div class="menu">
          <div class="menu-chunk" :class="{ pitchon: '/' == $route.fullPath }" @click.stop="gourl('/')">首页
          </div>
          <div class="menu-chunk" :class="{ pitchon: item.link == $route.fullPath }" v-for="(item, index) in pathList"
            :key="item.id" @click.stop="gourl(item.link)">{{ item.name }}
            <i class="pic" :class="item.link == is ? 'el-icon-error' : 'el-icon-close'" @mouseover="ison(item.link)"
              @mouseout="ison()" @click.stop="remove(index)"></i>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { UserModule } from '@/store/operation'
export default {
  name: 'HeaderName',
  computed: {
    ...mapState("optionsUser", ['userInfo']),
    getMenuinfoArr() {
      return this.$store.getters["optionsMenu/getMenuinfoArr"]
    },
  },
  created() {
    console.log(this.getMenuinfoArr)
  },
  data() {
    return {
      user: {
        name: '夏禹'
      },
      //访问过的链接
      pathList: [],
      is: null,
    }
  },
  methods: {
    // 跳转
    gourl(val) {
      console.log(val)
      this.$router.push({
        path: val
      })
    },
    ison(val) {
      this.is = val
    },
    // 怎么跳转
    isGourl(index){
      if(this.pathList[index]){
        this.gourl(this.pathList[index].link)
      }else{
        this.gourl(this.pathList[index-1].link)
      }

    },
    // 删除
    remove(index) {
      console.log(this.pathList)
      let arr = this.pathList.splice(index, 1);
      if (!this.pathList.length) {
        this.gourl('/')
      } else {
        // 自己删除自己怎么跳转
        if (arr[0].link == this.$route.fullPath) {
          this.isGourl(index)
        }
      }
    },
    //设置路由数组
    setPathList(path) {
      console.log("调用了,5255555555")
      let add = this.pathList.find(x => x.link === path);
      if (!add) {
        let item = this.getMenuinfoArr.find(x => x.link === path);
        item && this.pathList.push(item);
      }
    },
    //退出
    async logout() {
      UserModule.Logout();
      this.$router.push('/login');
    },
    fold() {
      this.$store.dispatch('cookies/setSidebarStatus')
    },
    handleScroll(e) {
      const wrap = this.$refs.scroll.$refs.wrap
      wrap.scrollLeft = wrap.scrollLeft - e.wheelDelta
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler(newValue, oldValue) {
        console.log(newValue, oldValue)
        this.setPathList(newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-box {
  height: 95px;
  background-color: #f2f2f2;
}

.header-one {
  padding: 0 40px 0 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}

.header-icon {
  font-size: 24px;
}

.header-user {
  position: relative;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
}

// .header-user:hover .user-shou {
//   display: block;
// }

.user-shou {
  cursor: pointer;
  display: none;
  width: 65px;
  position: absolute;
  left: -10px;
  top: 40px;
  padding: 10px 0;
  background-color: #fff;
}

.user-shou::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 30px;
  display: block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom: 5px solid #fff;
}

.close {
  display: block;
  padding: 5px;
  text-align: center;
}

.close:hover {
  background: #ecf5ff;
  color: #66b1ff;
}

.menu-box {
  padding: 0 40px 0 20px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}

.el-scrollbar {
  overflow: hidden;
  position: relative;
}

.menu {
  height: 45px;
  display: flex;
  align-items: center;
}

.menu-chunk {
  display: inline-block;
  flex: none;
  padding: 6px 8px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #d8dce5;
  color: #333;
}

:deep(.el-scrollbar__thumb) {
  display: none;
}

.el-scrollbar ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.pitchon {
  background-color: #42b983;
  color: #fff;
  border-color: #42b983;
}

.pitchon:before {
  content: "";
  background: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 5px;
}

.pic:hover {
  color: #b4bccc;
}
</style>