export default {
  namespaced: true,
  actions: {
    // 获取用户信息并设置
  },
  mutations: {
    setuserInfo(state, value) {
      state.userInfo = value
    }
  },
  state: {
    userInfo: {
    }
  },
  getters: {
    getuserInfoArr(state) {
      return Object.keys(state.userInfo)
    },
    getEnterpriseName(state) {
      return state.userInfo.enterpriseName
    },
    getEnterpriseid(state) {
      return state.userInfo.id
    }
  }
}