import axios from 'axios'
import { Loading, Message, MessageBox } from 'element-ui'
import { getTokenCookie, removeTokenCookie } from '@/utils/cookies'
import router from '@/router'
/**
 * axios封装
 * @param baseURL 请求前缀(根路径)
 * @param timeout 超时时间
 * @param loadingTime 显示loading时间
 */
const createService = ({ baseURL = '/api', timeout = 1000 * 20, loadingTime = 1000 * 2, loading = true }) => {
  console.log(loading, '调用了')
  const service = axios.create({
    baseURL,
    timeout
  })

  let loadingInstance = null
  let timer = null
  service.interceptors.request.use(
    async (config) => {
      // if (config.method === 'get' || config.method === 'GET') {
      //   console.log(config)
      //   // console.log(config.paramsSerializer)
      //   // config.paramsSerializer = params => {
      //   //   return qs.stringify(params, { indices: false })
      //   // }
      //   config.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets', strictNullHandling: true })
      // }
      // 暂时隐藏加载效果，但有遮罩层，超过两秒显示加载效果
      if (loading) {
        loadingInstance = Loading.service({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          customClass: 'create-isLoading',
          background: 'rgba(0, 0, 0, 0)'
        })
        timer = setTimeout(() => {
          loadingInstance.customClass = ''
        }, loadingTime)
      }

      config.headers['content-type'] = 'application/json'
      const token = getTokenCookie()
      if (token) {
        config.headers.AccessToken = token
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  // Response interceptors
  service.interceptors.response.use(
    (response) => {
      console.log(response)
      loadingInstance?.close()
      if (timer) {
        clearTimeout(timer)
      }
      const res = response.data
      if (response.status === 200) {
        if (res.success) {
          return res
        } else {
          if (res.code === 401) {
            MessageBox.confirm('当前登录状态已过期，请重新登录', '错误', {
              confirmButtonText: '重新登录',
              // cancelButtonText: 'Cancel',
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              type: 'warning'
            }).then(() => {
              removeTokenCookie()
              router.push('/login')
            })
          } else {
            const data = response.data
            Message.error(data.msg || data.message)
            return Promise.reject(response.data)
          }
        }
        // return res
      } else {
        console.warn('response:' + response + ' -> ' + JSON.stringify(response))
        // 为了兼容1.0的代码
        const data = response.data
        Message.error(data.msg || data.message)
        return Promise.reject(response.data)
      }
    },
    (error) => {
      loadingInstance?.close()
      if (timer) {
        clearTimeout(timer)
      }
      // 其他错误
      if (!error.response || !error.response.data) {
        Message.error('网络错误')
        return Promise.reject(error)
      }
      // 处理401问题
      if (error.response.code === 401) {
        MessageBox.confirm('当前登录状态已过期，请重新登录', '错误', {
          confirmButtonText: '重新登录',
          // cancelButtonText: 'Cancel',
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(() => {
          // const { UserModule } = require('@/store/modules/user')
          // UserModule.Logout()
          // removeTokenCookie()

          router.push('/login')
        })
      }
      const data = error.response.data
      Message.error(data.msg || data.message)
      return Promise.reject(error)
    }
  )

  return service
}

export default createService
