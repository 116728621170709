export default {
  namespaced: true,
  actions: {

  },
  mutations: {
    setShareinfo(state, value) {
      state.shareinfo = value
    }
  },
  state: {
    shareinfo: {}
  },
  getters: {
    getShareinfoArr(state) {
      return Object.keys(state.shareinfo)
    },
    getEnterpriseType(state) {
      return state.shareinfo.managementModelList
    }

  }
}