import { getSidebarStatus, setSidebarStatus } from '@/utils/cookies'
export default {
  namespaced: true,
  actions: {
    // 获取菜单是否折叠
    getSidebarStatus(context) {
      const res = getSidebarStatus()
      if (!res) {
        console.log(res)
        return
      }
      if (JSON.parse(res) !== Boolean(context.state.sidebar_status)) {
        context.commit('setSidebarStatus', JSON.parse(res))
      }
    },
    // 主动设置菜单折叠
    setSidebarStatus(context) {
      setSidebarStatus(!context.state.sidebar_status)
      context.commit('setSidebarStatus', !context.state.sidebar_status)
      setTimeout(() => {
        const myEvent = new Event('resize')
        window.dispatchEvent(myEvent)
      }, 500)
    }

  },
  mutations: {
    setSidebarStatus(state, value) {
      state.sidebar_status = value
    }
  },
  state: {
    sidebar_status: false
  }
}