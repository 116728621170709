<template>
  <div class="map">
    <div class="container">
      <el-input v-model="searchKey" class="search-topbox" disabled></el-input>
      <div class="search-box">
        <el-autocomplete v-model="querytext" :fetch-suggestions="querySearch" placeholder="定位" :trigger-on-focus="true"
          @select="handleSelect" />
      </div>
      <baidu-map id="allmap" style="flex:1" :center="mapCenter" :zoom="mapZoom" :scroll-wheel-zoom="true"
        @ready="mapReady" :mapClick="false" @click="getClickInfo">
        <bm-marker :position="point"></bm-marker>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"
          @locationSuccess="dingwei"></bm-geolocation>
      </baidu-map>
    </div>
    <div class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button @click="$emit('close', searchKey,point)">确 定</el-button>
    </div>
  </div>
</template>

<script>
// import BMap from 'BMap'
export default {
  // name: 'mapName'
  data() {
    return {
      searchKey: '',
      querytext: '',
      // 地图配置
      mapCenter: { lng: 0, lat: 0 },
      mapZoom: 15,
      BMap: null,
      map: null,
      point: ''
    }
  },
  methods: {

    dingwei(data) {
      const address = data.addressComponent.province + data.addressComponent.city
      const that = this
      const gc = new BMap.Geocoder()
      gc.getPoint(address, function (point) {
        if (point) {
          that.mapCenter.lng = point.lng
          that.mapCenter.lat = point.lat
          that.point = { lng: point.lng, lat: point.lat }
        }
      })
    },
    getClickInfo(data) {
      console.log(data)
      const that = this
      this.point = data.point
      const gc = new BMap.Geocoder()
      gc.getLocation(data.point, function (res) {
        const addrComponent = res.addressComponents
        const surroundingPois = res.surroundingPois
        let addr = addrComponent.street
        if (surroundingPois.length > 0 && surroundingPois[0].title) {
          if (addr) {
            addr += `-${surroundingPois[0].title}`
          } else {
            addr += `${surroundingPois[0].title}`
          }
        } else {
          addr += addrComponent.streetNumber
        }
        const add =
          addrComponent.province +
          addrComponent.city +
          addrComponent.district +
          addr
        that.searchKey = add
      })
    },
    mapReady({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      this.mapCenter.lng = 112.94432
      this.mapCenter.lat = 28.234126
      this.point = { lng: 112.94432, lat: 28.234126 }
      this.mapZoom = 10
    },
    handleSelect(item) {
      console.log(item)
      this.point = item.point
      this.searchKey = item.value
      this.makerCenter(item.point)
    },
    makerCenter(point) {
      if (this.map) {
        this.map.clearOverlays()
        this.map.addOverlay(new BMap.Marker(point))
        this.mapCenter.lng = point.lng
        this.mapCenter.lat = point.lat
        this.mapZoom = 15
      }
    },
    querySearch(queryString, cb) {
      const that = this
      const local = new BMap.LocalSearch(that.map, {
        renderOptions: { map: that.map }
      })
      local.search(queryString)
      const options = {
        onSearchComplete: function (results) {
          if (arrlocal.getStatus() === 0) {
            const s = []
            for (let i = 0; i < results.getCurrentNumPois(); i++) {
              const x = results.getPoi(i)
              const item = { value: x.address + x.title, point: x.point }
              s.push(item)
              cb(s)
            }
          } else {
            cb()
          }
        }
      }
      const arrlocal = new BMap.LocalSearch(this.map, options)
      arrlocal.search(queryString)
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: 80vh;
}

.el-autocomplete {
  width: 300px;
  margin-right: 20px;
}

.search-topbox {
  position: absolute;
  z-index: 5;
  width: 70%;
  left: 13%;
  top: 10px;
  height: 30px;
}

.container {
  height: 600px;
  display: flex;
}

.dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>