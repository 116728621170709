<template>
  <el-container class='my-container'>
    <el-aside :width='iscollapse ? " 60px" : "240px"'>
      <SlideBar></SlideBar>
    </el-aside>
    <el-container class='main-left-body'>
      <HeadBar></HeadBar>
      <el-main ref='appMain'>
        <AppMain ref='appMain'></AppMain>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import HeadBar from '@/layout/components/header.vue'
import SlideBar from '@/layout/components/sliderBar.vue'
import AppMain from '@/layout/components/AppMain.vue'
export default {
  name: 'FrameIndex',
  components: {
    HeadBar,
    SlideBar,
    AppMain
  },
  computed: {
    iscollapse() {
      return this.$store.state.cookies.sidebar_status
    }
  }
}
</script>

<style scoped>
.my-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.el-main {
  padding: 20px;
  height: calc(100vh - 75px);
  flex: 1;
  overflow-y: auto;
  display: flex;
  transition: 0.5s;
}

.el-aside {
  background-color: #131a2c;
  transition: 0.5s;
}

.main-left-body {
  flex-direction: column;
  background-color: #f0f2f5;
}
</style>