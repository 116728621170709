import { removeTokenCookie, setTokenCookie } from '@/utils/cookies'
import { userGetMyDetail, userRoleMyPower } from '@/api/login/index.js'
import * as util from '@/utils/util.js'
import store from '@/store/index.js'
const getModule = {
  // 设置token
  setToken(token) {
    setTokenCookie(token)
  },
  // 用户退出 删除token
  Logout() {
    removeTokenCookie()
    // 删除用户信息
    store.commit('optionsUser/setuserInfo', {})
    //删除相关菜单
    store.commit("optionsMenu/setMenuinfo", [])
    // 删除字典信息
    store.commit('optionsShare/setShareinfo', {})
  },
  // 获取用户相关的数据
  async getUser(value = false) {
    // 菜单是否折叠
    store.dispatch('cookies/getSidebarStatus')
    //  获取用户信息
    const userInfo = store.getters['optionsUser/getuserInfoArr']
    if (value || !userInfo || userInfo.length === 0) {
      // 设置用户信息
      const res = await userGetMyDetail()
      store.commit('optionsUser/setuserInfo', res.data)
    }
    //获取用户菜单
    let menuinfo = store.getters["optionsMenu/getMenuinfoArr"];
    //判断有没有用户菜单,是否强制更新菜单
    if (value || !menuinfo || menuinfo.length == 0) {
      let res = await userRoleMyPower()
      //存储树形菜单到store
      store.commit("optionsMenu/setMenuinfo", res.data)
      //存储数组菜单到store
      let menusList = util.treeToList(res.data)
      store.commit("optionsMenu/setMenuinfoArr", menusList)
      //获取权限按钮
    }
    // 获取字典
    const shareinfo = store.getters['optionsShare/getShareinfoArr']
    if (!shareinfo || shareinfo.length === 0) {
      // 获取字典信息
      // dictList().then(res => {
      //   store.commit('optionsShare/setShareinfo', res.data)
      // })
    }
  }
}
export const UserModule = getModule