import Vue from 'vue'
import Vuex from 'vuex'
import optionsUser from '@/store/modules/optionsUser'
import optionsShare from '@/store/modules/optionsShare'
import optionsMenu from "@/store/modules/optionsMenu"
import cookies from '@/store/modules/cookies'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // 用户信息
    optionsUser,
    // 字典信息
    optionsShare,
    // 菜单信息,
    optionsMenu,
    cookies
  }
})
