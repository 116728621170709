import router from '@/router/index.js'
import { UserModule } from '@/store/operation'
import store from '@/store/index.js'
import { getTokenCookie } from '@/utils/cookies'
import { Message } from 'element-ui'
router.beforeEach(async (to, from, next) => {
  if (getTokenCookie()) {
    await UserModule.getUser()
    if (to.path === '/login') {
      next("/")
    } else {
      if (to.path === '/') {
        next()
      } else {
        let menuinfo = store.getters["optionsMenu/getMenuinfoArr"];
        //判断是否存在这个路由,不存在则提示并跳回首页
        if (menuinfo.find(x => x.link === to.path) == null) {
          Message.error('当前登录用户无权限访问此页面')
          next({ path: '/' })
        } else {
          next()
        }
      }
    }
  } else {
    //判断去哪里,登入页,面登入页面直接放行,其他页面直接跳到登入页面去.
    if (to.path.indexOf('/login') > -1) {
      next()
    } else {
      next('/login')
    }
  }
  // await UserModule.getUser()
  // next()
})
router.afterEach(() => {
  document.title = '桶装水系统'
})