<template>
  <router-view :key='single' />
</template>

<script>
export default {
  name: 'appMain',
  computed: {
    single() {
      return this.$route.path
    }
  }
}
</script>
