/**
 * 全局混入
 */
import { formatQuery } from '@/utils/util'
export default {
  props: {
    layerid: {
      // 自动注入的layerid
      type: String,
      default: ''
    },
    iframeData: {
      // 传递的数据
      type: Object,
      default: () => {
        return {}
      }
    },
    lydata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      pickerOptionsEnd: {},
      pickerOptionsStart: {}
    }
  },

  /**
   * 全局方法
   */
  methods: {
    hint() {
      this.$message({
        message: '操作成功',
        type: 'success'
      })
    },

    // 查询条件不能为空判断
    filtrateNotBlank(val) {
      const obj = formatQuery(val)
      const arr = Object.keys(obj)
      if (arr.length > 2) {
        return true
      }
      return false
    },
    // 分页序号
    indexMethod(index) {
      if (this.page) {
        return index + 1 + (this.page - 1) * this.size
      }
      if (this.queryParams && this.queryParams.page) {
        return index + 1 + (this.queryParams.page - 1) * this.queryParams.size
      }
      if (this.condition && this.condition.page) {
        return index + 1 + (this.condition.page - 1) * this.condition.size
      }
      if (this.queryModel && this.queryModel.page) {
        return index + 1 + (this.queryModel.page - 1) * this.queryModel.size
      }
      return index + 1
    },
    // 结束时间限制开始时间 (这里写的是反的，选择结束时间调用changeStart,方法必须带())
    changeStart(endDate = 'queryParams.endTime') {
      const getEndDate = new Function('that', `return that.${endDate}`)
      if (!getEndDate(this)) {
        this.pickerOptionsStart = {
          disabledDate: {}
        }
        return
      }
      this.pickerOptionsStart = Object.assign({}, this.pickerOptionsStart, {
        // 可通过箭头函数的方式访问到this
        disabledDate: time => {
          let times = ''
          times = time.getTime() >= new Date(getEndDate(this)).getTime()
          return times
        }
      })
    },
    // 开始时间 控制结束时间 (这里写的是反的，选择结束时间调用changeEnd,方法必须带())
    changeEnd(startDate = 'queryParams.startTime') {
      const getStartDate = new Function('that', `return that.${startDate}`)
      if (!getStartDate(this)) {
        this.pickerOptionsEnd = {
          disabledDate: {}
        }
        return
      }
      this.pickerOptionsEnd = Object.assign({}, this.pickerOptionsEnd, {
        disabledDate: time => {
          return time.getTime() <= new Date(getStartDate(this)).getTime() - 24 * 60 * 60 * 1000 + 1
        }
      })
    },
    closeDialog() {
      this.$layer.close(this.layerid)
    }
  }
}
