import Cookies from 'js-cookie'

// 设置菜单折叠
const sidebarStatusKey = 'sidebar_status'
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey)
export const setSidebarStatus = (sidebarStatus) => Cookies.set(sidebarStatusKey, sidebarStatus)

// User -> token
const tokenKey = 'bottled_web_token'
export const getTokenCookie = () => Cookies.get(tokenKey)
export const setTokenCookie = (token) => Cookies.set(tokenKey, token, { expires: 365, path: '' })
export const removeTokenCookie = () => Cookies.remove(tokenKey)

// export const getTokenCookie = () => localStorage.getItem(tokenKey)
// export const setTokenCookie = (token) => localStorage.setItem(tokenKey, token)
// export const removeTokenCookie = () => localStorage.removeItem(tokenKey)