import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/login',
    meta: {
      title: '登入'
    },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/',
    component: layout,
    meta: {
      title: '首页模块'
    },
    children: [
      {
        path: '/',
        component: () => import('@/views/homePage/index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/clientType',
        component: () => import('@/views/clientType/index.vue'),
        meta: {
          title: '客户类型'
        }
      },
      {
        path: '/spotType',
        component: () => import('@/views/spotType/index.vue'),
        meta: {
          title: '网点类型'
        }
      },
      {
        path: '/commodityList',
        component: () => import('@/views/commodityList/index.vue'),
        meta: {
          title: '商品列表'
        }
      },
      {
        path: '/secondaryList',
        component: () => import('@/views/secondaryList/index.vue'),
        meta: {
          title: '次卡列表'
        }
      },
      {
        path: '/honeycomb',
        component: () => import('@/views/honeycomb/index.vue'),
        meta: {
          title: '蜂窝管理'
        }
      },
      {
        path: '/gridding',
        component: () => import('@/views/gridding/index.vue'),
        meta: {
          title: '网格管理'
        }
      },
      {
        path: '/community',
        component: () => import('@/views/community/index.vue'),
        meta: {
          title: '小区管理'
        }
      },
      {
        path: '/spot',
        component: () => import('@/views/spot/index.vue'),
        meta: {
          title: '网点管理'
        }
      },
      {
        path: '/inventoryRecords',
        component: () => import('@/views/inventoryRecords/index.vue'),
        meta: {
          title: '补货报损明细'
        }
      },
      {
        path: '/spotMarket',
        component: () => import('@/views/spotMarket/index.vue'),
        meta: {
          title: '网点销售'
        }
      },
      {
        path: '/feedback',
        component: () => import('@/views/feedback/index.vue'),
        meta: {
          title: '查看反馈'
        }
      },
      {
        path: '/staffManagement',
        component: () => import('@/views/staffManagement/index.vue'),
        meta: {
          title: '员工管理'
        }
      },
      {
        path: '/repertory',
        component: () => import('@/views/repertory/index.vue'),
        meta: {
          title: '库存主体'
        }
      },
      {
        path: '/qrcode',
        component: () => import('@/views/qrcode/index.vue'),
        meta: {
          title: '二维码管理'
        }
      },
      {
        path: '/menus',
        component: () => import('@/views/menus/index.vue'),
        meta: {
          title: '菜单管理'
        }
      },
      {
        path: '/role',
        component: () => import('@/views/role/index.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/websiteRepertory',
        component: () => import('@/views/websiteRepertory/index.vue'),
        meta: {
          title: '网点库存'
        }
      },
      {
        path: '/stockAdjustment',
        component: () => import('@/views/stockAdjustment/index.vue'),
        meta: {
          title: '库存调整'
        }
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
