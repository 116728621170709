import request from '@/utils/request'

/**
 * 手机号密码登录
 * @param data
 * @returns {AxiosPromise}
 */
export const userLogin = data => request({ url: '/user/login', method: 'post', data })

/**
 * 获取当前登录用户信息
 * @param data
 * @returns {AxiosPromise}
 */
export const userGetMyDetail = () => request({ url: '/user/getMyDetail', method: 'post' })

/**
 * 获取当前登录用户菜单权限
 * @param data
 * @returns {AxiosPromise}
 */
export const userRoleMyPower = () => request({ url: '/userRole/myPower', method: 'get' })