<template>
  <el-menu :default-active='$route.path' router background-color='#131a2c' :unique-opened='true' :collapse='iscollapse'
    :collapse-transition='false' mode='vertical' text-color='#fff' active-text-color='#257bcc' class='menu'>
    <el-menu-item class='home' index="/">
      <!-- <el-image :src='require("@/assets/layout/home.png")' fit='fill'></el-image> -->
      <i class='el-icon-menu' fit='fill'></i>
      <span slot='title'>首页</span>
    </el-menu-item>
    <SidebarItem :data='menu'></SidebarItem>
  </el-menu>
</template>

<script>
import SidebarItem from '@/layout/components/SidebarItem.vue'
export default {
  components: {
    SidebarItem
  },
  data() {
    return {
      // menu: data
    }
  },
  created() {
    console.log(this.menu)
  },
  computed: {
    iscollapse() {
      return this.$store.state.cookies.sidebar_status
    },
    menu() {
      const menu = this.$store.getters['optionsMenu/getMenuinfo']
      return menu
    }
    // menu() {
    //   const menu = this.$store.getters['optionsMenu/getHomeMenuinfo']?.children
    //   return menu
    // }
  },
  methods: {

  }
}
</script>

<style scoped>
.el-menu {
  border-right: none;
}

.el-menu-item {
  color: #fff;
}

.el-menu-item:hover {
  background-color: #212C3B !important;
}

.el-image {
  width: 14px;
  height: 14px;
}

span {
  margin-left: 14px;
}
</style>